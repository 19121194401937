<template>
  <v-container style="display: flex; flex-wrap: wrap" fluid>
    <v-row style="padding-top: 150px">
      <v-spacer></v-spacer>
      <v-col>
        <v-card>
          <v-card-text>Please wait while you are logged out...</v-card-text>
        </v-card>
      </v-col>
      <v-spacer></v-spacer>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";

// @ is an alias to /src
export default {
  name: "Login",
  data: () => ({}),
  mounted() {
    this.handleLogout();
  },
  methods: {
    ...mapMutations(["logout"]),
    handleLogout() {
      this.logout(); // note: This will call auth/logout along with all other module logout methods.
      this.hardNavTo(
        "api/logout?post_logout_redirect_uri=" + encodeURIComponent("/")
      );
    },
    hardNavTo(href) {
      window.location.href = href;
    },
  },
  computed: {},
};
</script>
